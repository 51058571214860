const nav = () => {
  $(function() {

      var newHash      = "",
          $mainContent = $("#main-content"),
          $pageWrap    = $("#page-wrap"),
          baseHeight   = 1,
          $el;

      $pageWrap.height($pageWrap.height());
      baseHeight = $pageWrap.height() - $mainContent.height();

      $("nav").delegate("a", "click", function() {
          window.location.hash = $(this).attr("href");
          return false;
      });

      $(window).bind('hashchange', function(){

          newHash = window.location.hash.substring(1);

          if (newHash) {
              $mainContent
                  .find("#guts")
                  .fadeOut(200, function() {
                      $mainContent.hide().load(newHash + " #guts", function() {
                          $mainContent.fadeIn(200, function() {
                          });
                          $("nav a").removeClass("current");
                          $("nav a[href*=\\"+newHash+"]").addClass("current");
                      });
                  });
          };

      });

      $(window).trigger('hashchange');

  });
}

export {nav};
